<template>
  <div v-loading="isLoading" class="entrance__form">
    <el-form
      ref="login"
      status-icon
      label-width="140px"
      label-position="top"
      :model="formData"
      :rules="currentRules"
      @keyup.enter.native="$emit('emitLogin')"
    >
      <div class="df m-b-5">
        <IqLogRadio @input="answer" />
      </div>

      <div v-show="formOption" class="df m-b-5" style="flex-direction: column">
        <div class="m-b-5">Телефон</div>
        <el-form-item ref="phone" prop="phone" :rules="currentRules.phone">
          <el-input
            v-model="formData.phone"
            v-mask="'+7 (###) ###-##-##'"
            class="entrance__form-input"
            placeholder="Введите телефон"
          />
        </el-form-item>
      </div>
      <div v-show="!formOption" class="df m-b-5" style="flex-direction: column">
        <div class="m-b-5">Логин</div>
        <el-form-item ref="login" prop="login" :rules="currentRules.login">
          <el-input
            v-model="formData.login"
            class="entrance__form-input"
            placeholder="Введите логин"
          />
        </el-form-item>
      </div>

      <div class="df m-b-5" style="flex-direction: column">
        <div class="m-b-5">Пароль</div>
        <el-form-item
          ref="password"
          prop="password"
          :rules="currentRules.password"
        >
          <el-input
            v-model="formData.password"
            class="entrance__form-input"
            placeholder="Введите пароль"
            type="password"
          />
        </el-form-item>
      </div>
      <div class="m-t-17">
        <el-form-item>
          <iq-button
            style="width: 100%"
            color="primary"
            @onClick="handleLogin('login')"
          >
            <span class="mobile-login_form-btn"> Войти </span>
          </iq-button>
        </el-form-item>
      </div>
    </el-form>
    <div class="entrance__form-wrapper">
      <el-link
        type="primary"
        :underline="false"
        class="entrance__form-link"
        @click="forgotPassword"
      >
        Забыли пароль?
      </el-link>
    </div>
  </div>
</template>

<script>
import entranceMixin from '@/views/login/data/entranceMixin'
export default {
  name: 'EntranceFormMobile',
  mixins: [entranceMixin],
}
</script>

<style lang="sass" scoped>
@import '../data/entranceStyles.sass'
</style>
